import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import {
  CrmPageHeaderComponent,
  CrmPageHeaderConfig,
} from 'common-module/page-header';
import { CrmPagination } from 'common-module/table';
import { CrmTranslateMessage } from 'common-module/translate';

import { FilterComponent } from '../filter/filter.component';
import { FilterField } from '../filter/filter.model';
import { TableComponent } from '../table/table.component';
import { TableColumn, TableRow } from '../table/table.model';

@Component({
  standalone: true,
  selector: 'app-list',
  templateUrl: 'list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [FilterComponent, CrmPageHeaderComponent, TableComponent],
})
export class ListComponent<Row extends TableRow> {
  @HostBinding('class') protected readonly hostClass = 'ehr-list';

  loading = input.required<boolean>();
  header = input.required<CrmPageHeaderConfig<Row>>();
  title = input<CrmTranslateMessage>();
  filters = input<FilterField[]>([]);
  columns = input<TableColumn<Row>[]>([]);
  pageSizeOptions = input<number[]>([10, 20, 30, 40, 50]);
  pageSizeHidden = input<boolean>(false);
  outerBordered = input<boolean>(false);
  bordered = input<boolean>(false);
  selectable = input<boolean>(false);
  expandable = input<boolean>(false);
  maxSelection = input<number>();
  rowClickStrategy = input<'click' | 'select'>();
  rows = input<Row[]>([]);

  pagination = input<CrmPagination | undefined>();
  filter = input<CrmDictionary>({});
  sort = input<CrmDictionary<'ascend' | 'descend'>>({});
  selected = input<string[]>([]);

  paginationChange = output<CrmPagination | undefined>();
  filterChange = output<CrmDictionary>({});
  sortChange = output<CrmDictionary<'ascend' | 'descend'>>({});
  selectedChange = output<string[]>();

  rowClick = output<string>();
}
