import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  ProviderToken,
  runInInjectionContext,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  CrmActionButton,
  CrmActionButtonsComponent,
} from 'common-module/buttons';
import { CrmTranslateMessage } from 'common-module/translate';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { BehaviorSubject, map, Observable, of } from 'rxjs';

import { ProvidedTableComponent } from '../table/provided-table.component';
import { TableRow } from '../table/table.model';
import { TableProvider } from '../table/table.provider';

@Component({
  standalone: true,
  selector: 'app-table-selection',
  template: `
    <div class="table-selection">
      <app-provided-table [provider]="_provider"></app-provided-table>
    </div>
    <ng-container *nzModalFooter>
      <crm-action-buttons [actions]="actions"></crm-action-buttons>
    </ng-container>
  `,
  styles: [
    `
      :host .table-selection ::ng-deep {
        .ant-table-wrapper {
          max-height: 400px;
          overflow: auto;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CrmActionButtonsComponent, ProvidedTableComponent, NzModalModule],
})
export class TableSelectionComponent<Row extends TableRow>
  implements OnInit, OnChanges
{
  @Output() confirm$ = new EventEmitter<string[]>();
  @Output() cancel$ = new EventEmitter<void>();

  @Input() provider?: TableProvider<Row>;
  @Input() providerToken?: ProviderToken<TableProvider<Row>>;
  @Input() confirmTitle!: CrmTranslateMessage;
  @Input() allowEmpty?: boolean;
  @Input() isLoading?: boolean;

  protected _provider!: TableProvider<Row>;
  actions!: CrmActionButton[];

  private readonly injector = inject(Injector);
  private readonly isLoading$ = new BehaviorSubject(false);
  private confirmDisabled$!: Observable<boolean>;

  ngOnInit() {
    runInInjectionContext(this.injector, () => {
      if (!this._provider && this.provider) {
        this._provider = this.provider;
      }

      if (!this._provider && this.providerToken) {
        this._provider = inject(this.providerToken);
      }

      if (!this._provider) {
        throw new Error('No provider!');
      }

      this.confirmDisabled$ = toObservable(this._provider.selected).pipe(
        map((selected) => selected.length === 0),
      );
    });
    this.initActions();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.handleConfirmTitleChange(changes['confirmTitle']);

    this.isLoading$.next(this.isLoading ?? false);
  }

  private handleConfirmTitleChange(title: SimpleChange) {
    if (!title || title.firstChange || !title.currentValue) {
      return;
    }

    this.initActions();
  }

  private initActions() {
    this.actions = [
      {
        id: 'cancel',
        title: 'generic.cancel',
        type: 'default',
        size: 'default',
        action: () => this.cancel$.next(),
      },
      {
        id: 'confirm',
        title: this.confirmTitle,
        type: 'primary',
        size: 'default',
        isLoading$: this.isLoading$,
        isDisabled$: () => {
          if (this.allowEmpty) {
            return of(false);
          }

          return this.confirmDisabled$;
        },
        action: () => this.confirm$.next(this._provider.selected() ?? []),
      },
    ];
  }
}
