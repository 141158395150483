import { signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpointListResponse } from 'common-module/endpoint';
import { CrmPagination } from 'common-module/table';
import { cloneDeep, isEqual, isNil, omit } from 'lodash-es';
import { combineLatest, of, skip } from 'rxjs';

import { ProvidedProvider } from '../../provided/provided.provider';

import { TableConfig, TableData, TableRow } from './table.model';

export abstract class TableProvider<
  Row extends TableRow,
  Config extends TableConfig<Row> = TableConfig<Row>,
  Data extends TableData<Row> = TableData<Row>,
> extends ProvidedProvider<Config, Data> {
  selected = signal<string[]>([]);
  filter = signal<CrmDictionary>({});
  sort = signal<CrmDictionary<'ascend' | 'descend'>>({});
  pagination = signal<CrmPagination | undefined>(undefined);

  protected defaultPagination: CrmPagination = { skip: 0, limit: 50 };

  constructor() {
    super();

    combineLatest({
      filter: toObservable(this.filter),
      sort: toObservable(this.sort),
      pagination: toObservable(this.pagination),
    })
      .pipe(skip(1), takeUntilDestroyed())
      .subscribe((data) => this.reload(data));
  }

  handleRowClick(_: string) {}

  override serializeQueryParams(params: CrmDictionary): CrmDictionary {
    const { pagination, filter = {}, sort = {} } = cloneDeep(params);

    const transformed = { filter, sort, ...pagination };

    Object.entries(filter).reduce((result, [key, value]) => {
      result[key] = this.serializeQueryParamValue(value);
      return result;
    }, transformed.filter ?? {});

    return cloneDeep(transformed);
  }

  override deserializeQueryParams(params: CrmDictionary): CrmDictionary {
    const transformed: CrmDictionary = super.deserializeQueryParams(params);

    if (params['skip']) {
      const skip = Number(params['skip']);
      delete params['skip'];
      if (!Number.isNaN(skip)) {
        transformed['skip'] = skip;
      }
    }
    if (params['limit']) {
      const limit = Number(params['limit']);
      delete params['limit'];
      if (!Number.isNaN(limit)) {
        transformed['limit'] = limit;
      }
    }

    return cloneDeep({ ...params, ...transformed });
  }

  protected override resolveDataFromQueryParams(data: {
    filter?: CrmDictionary;
    sort?: CrmDictionary<'ascend' | 'descend'>;
    skip?: number;
    limit?: number;
  }) {
    const { filter = {}, sort = {}, skip, limit } = data;
    const pagination =
      !isNil(skip) && !isNil(limit) ? { skip, limit } : undefined;

    return of({ filter, pagination, sort });
  }

  protected override getDataToStore(data?: {
    pagination?: CrmPagination;
    sort?: CrmDictionary<'ascend' | 'descend'>;
    filter?: CrmDictionary;
  }) {
    const { pagination, filter, sort } = data ?? {};

    const currentPagination = omit(pagination, 'total');
    const updatePagination = !isEqual(
      currentPagination,
      this.defaultPagination,
    );

    return {
      pagination: updatePagination ? currentPagination : {},
      sort,
      filter,
      selected: this.selected(),
    };
  }

  protected getPaginationFromResponse(
    response: CrmEndpointListResponse<CrmDictionary>,
  ) {
    if (response.total > this.defaultPagination.limit) {
      return omit(response, 'data');
    }

    return undefined;
  }
}
