@if (header(); as header) {
  <crm-page-header
    class="ehr-list__header"
    [title]="header.title"
    [breadcrumbs]="header.breadcrumbs"
    [actionsConfig]="header.actionsConfig"
  ></crm-page-header>
}

<app-table
  class="ehr-list__table"
  [title]="title()"
  [loading]="loading()"
  [filters]="filters()"
  [columns]="columns()"
  [bordered]="bordered()"
  [outerBordered]="outerBordered()"
  [selectable]="selectable()"
  [maxSelection]="maxSelection()"
  [rowClickStrategy]="rowClickStrategy()"
  [pageSizeOptions]="pageSizeOptions()"
  [pageSizeHidden]="pageSizeHidden()"
  [rows]="rows()"
  [sort]="sort()"
  [selected]="selected()"
  [filter]="filter()"
  [pagination]="pagination()"
  (sortChange)="sortChange.emit($event)"
  (filterChange)="filterChange.emit($event)"
  (selectedChange)="selectedChange.emit($event)"
  (paginationChange)="paginationChange.emit($event)"
  (rowClick)="rowClick.emit($event)"
></app-table>
